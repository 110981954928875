"use client"

import * as SelectPrimitive from "@radix-ui/react-select"
import * as SeparatorPrimitive from "@radix-ui/react-separator"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { cva, type VariantProps } from "class-variance-authority"
import { AnimatePresence, motion } from "framer-motion"
import {
  AlertTriangle,
  Check,
  CheckCircle,
  ChevronDown,
  Info,
  LucideIcon,
  X,
  XCircle,
} from "lucide-react"
import React, { forwardRef, ReactNode, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import ReactSelect, { components, Props as ReactSelectProps } from "react-select"
import { BarLoader } from "react-spinners"

//................................................................................... Menu item type

export type MenuItem = {
  path: string
  title: string
  icon?: LucideIcon
  children?: MenuItem[]
  subtitle?: string
}

export function isMenuItemActive(item: MenuItem, pathname: string) {
  return pathname.startsWith(item.path)
}

//.......................................................................................... Button

const buttonVariants = cva(
  "relative inline-flex items-center justify-center rounded focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors",
  {
    variants: {
      variant: {
        default: "text-white",
        outline: "bg-transparent border",
        subtle: "",
        ghost: "bg-transparent",
        link: "bg-transparent underline-offset-4 hover:underline focus:ring-0 focus:ring-offset-0 px-0",
      },
      color: {
        neutral: "focus:ring-neutral-500",
        primary: "focus:ring-primary-500",
        red: "focus:ring-red-500",
        green: "focus:ring-green-500",
        blue: "focus:ring-blue-500",
      },
      size: {
        default: "px-4 py-2 text-base",
        xs: "px-2 py-1 text-xs",
        sm: "px-3 py-1.5 text-sm",
        lg: "px-5 py-2.5 text-lg",
        xl: "px-6 py-3.5 text-xl",
      },
    },
    compoundVariants: [
      // Default variant color combinations
      {
        variant: "default",
        color: "neutral",
        className: "bg-neutral-800 hover:bg-neutral-700",
      },
      {
        variant: "default",
        color: "primary",
        className: "bg-primary-500 hover:bg-primary-600",
      },
      {
        variant: "default",
        color: "red",
        className: "bg-red-500 hover:bg-red-600",
      },
      {
        variant: "default",
        color: "green",
        className: "bg-green-600 hover:bg-green-700",
      },
      {
        variant: "default",
        color: "blue",
        className: "bg-blue-600 hover:bg-blue-700",
      },
      // Outline variant color combinations
      {
        variant: "outline",
        color: "neutral",
        className:
          "border-neutral-300 text-neutral-800 hover:bg-neutral-100 hover:border-neutral-400",
      },
      {
        variant: "outline",
        color: "primary",
        className:
          "border-primary-200 text-primary-500 hover:border-primary-400 hover:bg-primary-50",
      },
      {
        variant: "outline",
        color: "red",
        className: "border-red-300 text-red-500 hover:border-red-400",
      },
      {
        variant: "outline",
        color: "green",
        className: "border-green-300 text-green-600 hover:border-green-400",
      },
      {
        variant: "outline",
        color: "blue",
        className: "border-blue-300 text-blue-600 hover:border-blue-400",
      },
      // Outline variant color combinations
      {
        variant: "ghost",
        color: "neutral",
        className: "text-neutral-800 hover:bg-neutral-200 hover:text-neutral-900",
      },
      {
        variant: "ghost",
        color: "primary",
        className: "text-primary-600 hover:bg-primary-100 hover:text-primary-700",
      },
      {
        variant: "ghost",
        color: "red",
        className: "text-red-500 hover:bg-red-200 hover:text-red-700",
      },
      {
        variant: "ghost",
        color: "green",
        className: "text-green-600 hover:bg-green-100",
      },
      {
        variant: "ghost",
        color: "blue",
        className: "text-blue-600 hover:bg-blue-100",
      },
      // Subtle variant color combinations
      {
        variant: "subtle",
        color: "neutral",
        className: "bg-neutral-100 text-neutral-800 hover:bg-neutral-200 hover:text-neutral-900",
      },
      {
        variant: "subtle",
        color: "primary",
        className: "bg-primary-50 text-primary-600 hover:bg-primary-100 hover:text-primary-700",
      },
      {
        variant: "subtle",
        color: "red",
        className: "bg-red-50 text-red-500 hover:bg-red-100 hover:text-red-600",
      },
      {
        variant: "subtle",
        color: "green",
        className: "bg-green-50 text-green-500 hover:bg-green-100 hover:text-green-600",
      },
      {
        variant: "subtle",
        color: "blue",
        className: "bg-blue-50 text-blue-500 hover:bg-blue-100 hover:text-blue-600",
      },

      // Link variant color combinations
      {
        variant: "link",
        color: "neutral",
        className: "text-neutral-600 hover:text-neutral-900",
      },
      {
        variant: "link",
        color: "primary",
        className: "text-primary-500 hover:text-primary-700",
      },
      {
        variant: "link",
        color: "red",
        className: "text-red-500 hover:text-red-700",
      },
      {
        variant: "link",
        color: "green",
        className: "text-green-600 hover:text-green-700",
      },
      {
        variant: "link",
        color: "blue",
        className: "text-blue-600 hover:text-blue-700",
      },
    ],
    defaultVariants: {
      variant: "default",
      color: "primary",
      size: "default",
    },
  }
)

type AsProp<C extends React.ElementType> = {
  as?: C
}

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P)

type PolymorphicComponentProp<C extends React.ElementType, Props = {}> = React.PropsWithChildren<
  Props & AsProp<C>
> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>

type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>["ref"]

type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = {}
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> }

export type ButtonProps = {
  loading?: boolean
  children: ReactNode
  leftIcon?: LucideIcon
  rightIcon?: LucideIcon
  className?: string
} & VariantProps<typeof buttonVariants>

type DefaultElementType = "button"

type ButtonComponentType = <C extends React.ElementType = DefaultElementType>(
  props: PolymorphicComponentPropWithRef<C, ButtonProps>
) => React.ReactElement | null

// @ts-expect-error stuff
export const Button: ButtonComponentType = forwardRef(
  // @ts-expect-error stuff
  <C extends React.ElementType = DefaultElementType>(
    {
      loading,
      as,
      children,
      className,
      variant,
      color,
      size,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      ...props
    }: PolymorphicComponentPropWithRef<C, ButtonProps>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "button"
    size = size || "default"

    const iconClassName = {
      default: "w-5 h-5",
      xs: "w-3 h-3",
      sm: "w-4 h-4",
      lg: "w-6 h-6",
      xl: "w-6 h-6",
    }

    const iconMarginsClassName = {
      default: ["mr-2", "ml-2"],
      xs: ["mr-2", "ml-2"],
      sm: ["mr-2", "ml-2"],
      lg: ["mr-3", "ml-3"],
      xl: ["mr-4", "ml-4"],
    }

    const baseClassName = buttonVariants({ variant, color, size, className })

    return (
      <Component ref={ref} className={baseClassName} {...props}>
        {loading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <LoadingSpinner size={"sm"} />
          </div>
        )}
        {LeftIcon && (
          <LeftIcon
            style={{ opacity: loading ? 0 : 1 }}
            className={`${iconClassName[size]} ${iconMarginsClassName[size][0]}`}
          />
        )}
        <div style={{ position: "relative", opacity: loading ? 0 : 1 }}>{children}</div>
        {RightIcon && (
          <RightIcon
            style={{ opacity: loading ? 0 : 1 }}
            className={`${iconClassName[size]} ${iconMarginsClassName[size][1]}`}
          />
        )}
      </Component>
    )
  }
)

// @ts-expect-error stuff
Button.displayName = "Button"

//.......................................................................................... Inline Link

const inlineLinkVariants = cva(
  "inline underline-offset-4 hover:underline focus:outline-none transition-colors",
  {
    variants: {
      color: {
        neutral: "text-neutral-600 hover:text-neutral-900",
        primary: "text-primary-500 hover:text-primary-700",
        red: "text-red-500 hover:text-red-700",
        green: "text-green-600 hover:text-green-700",
        blue: "text-blue-600 hover:text-blue-700",
      },
    },
    defaultVariants: {
      color: "primary",
    },
  }
)

export type InlineLinkProps = {
  className?: string
  children: React.ReactNode
} & VariantProps<typeof inlineLinkVariants>

type InlineLinkComponentType = <C extends React.ElementType = "a">(
  props: PolymorphicComponentPropWithRef<C, InlineLinkProps>
) => React.ReactElement | null

// @ts-expect-error stuff
export const InlineLink: InlineLinkComponentType = React.forwardRef(
  // @ts-expect-error stuff
  <C extends React.ElementType = "a">(
    {
      as,
      color,
      className,
      children,
      ...props
    }: PolymorphicComponentPropWithRef<C, InlineLinkProps>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "a"
    const baseClassName = inlineLinkVariants({ color, className })

    return (
      <Component ref={ref} className={baseClassName} {...props}>
        {children}
      </Component>
    )
  }
)

// @ts-expect-error
InlineLink.displayName = "InlineLink"

//.......................................................................................... Top bar

export interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = ({}) => {
  return (
    <div className="bg-white p-2 flex justify-between items-center">
      <div className="flex items-center">
        <h1 className="text-xl font-bold">Quickreel</h1>
      </div>
    </div>
  )
}

//.......................................................................................... Input

type InputSize = "sm" | "md" | "lg"

type InputProps = {
  label?: string
  error?: string
  helperText?: string
  readonly?: boolean
  size?: InputSize
} & Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "id" | "value" | "onChange" | "className" | "autoFocus" | "placeholder" | "type" | "name"
>

const sizeStyles: Record<InputSize, string> = {
  sm: "h-8 text-sm px-2 py-1",
  md: "h-10 px-3 py-2",
  lg: "h-12 text-lg px-4 py-2",
}

const labelSizeStyles: Record<InputSize, string> = {
  sm: "text-xs",
  md: "text-sm",
  lg: "text-base",
}

const helperTextSizeStyles: Record<InputSize, string> = {
  sm: "text-xs",
  md: "text-xs",
  lg: "text-sm",
}

const errorSizeStyles: Record<InputSize, string> = {
  sm: "text-xs",
  md: "text-xs",
  lg: "text-sm",
}

export const Input: React.FC<InputProps> = ({
  id,
  value,
  onChange,
  className = "",
  label,
  helperText,
  error,
  autoFocus,
  readonly = false,
  placeholder,
  type,
  name,
  size = "md",
}) => (
  <div className="flex flex-col">
    {label && (
      <label htmlFor={id} className={`mb-1 font-medium text-neutral-700 ${labelSizeStyles[size]}`}>
        {label}
      </label>
    )}
    <input
      name={name}
      type={type}
      autoFocus={autoFocus}
      id={id}
      value={value}
      onChange={onChange}
      readOnly={readonly}
      placeholder={placeholder}
      className={`border border-neutral-300 rounded-md focus:outline-none focus:ring-2
         focus:ring-primary-500 focus:border-transparent ${sizeStyles[size]} ${className}
          ${error ? "border-red-500" : ""}
          ${readonly ? "bg-neutral-50" : ""}
         `}
    />
    {helperText && !error && (
      <p className={`text-neutral-600 mt-1 ${helperTextSizeStyles[size]}`}>{helperText}</p>
    )}
    {error && <p className={`text-red-500 mt-1 ${errorSizeStyles[size]}`}>{error}</p>}
  </div>
)

Input.displayName = "Input"

//.......................................................................................... Dialog

interface DialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  children: ReactNode
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full"
  scrollBehavior?: "inside" | "outside"
}

const sizeClasses = {
  xs: "max-w-xs",
  sm: "max-w-sm",
  md: "max-w-md",
  lg: "max-w-lg",
  xl: "max-w-xl",
  full: "max-w-full mx-4",
}

export const Dialog: React.FC<DialogProps> = ({ open, onOpenChange, children, size = "md" }) => {
  const dialogRef = useRef<HTMLDivElement>(null)
  const [isMouseDownOnBackdrop, setIsMouseDownOnBackdrop] = useState(false)

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onOpenChange(false)
      }
    }

    if (open) {
      document.addEventListener("keydown", handleEscape)
      try {
        document.body.style.overflow = "hidden"
      } catch {
        // ignore
      }
    }

    return () => {
      document.removeEventListener("keydown", handleEscape)
      try {
        document.body.style.overflow = ""
      } catch {
        // ignore
      }
    }
  }, [open, onOpenChange])

  const contentClasses = `
    bg-white rounded-lg shadow-xl w-full 
    ${sizeClasses[size]} 
  `

  const containerClasses = `
    fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4
  `

  const handleBackdropMouseDown = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsMouseDownOnBackdrop(true)
    }
  }

  const handleBackdropMouseUp = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget && isMouseDownOnBackdrop) {
      onOpenChange(false)
    }
    setIsMouseDownOnBackdrop(false)
  }

  // Check if document.body is available
  try {
    document.body
  } catch {
    return null
    // ignore
  }

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={containerClasses}
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0, transition: { duration: 0.15 } }}
            transition={{ type: "spring", damping: 20, stiffness: 300 }}
            ref={dialogRef}
            className={contentClasses}
            role="dialog"
            aria-modal="true"
            onMouseDown={(e) => e.stopPropagation()}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export const DialogContent: React.FC<{
  children: ReactNode
  className?: string
}> = ({ children, className = "" }) => (
  <div className={`flex-grow p-6 relative w-full ${className}`}>{children}</div>
)

export const DialogHeader: React.FC<{
  children?: ReactNode
  className?: string
}> = ({ children, className = "" }) => (
  <div className={`px-6 pt-6 flex items-center justify-between flex-row ${className}`}>
    {children}
  </div>
)

export const DialogTitle: React.FC<{
  children: ReactNode
  className?: string
}> = ({ children, className = "" }) => (
  <h2 className={`text-xl font-semibold ${className}`} id="dialog-title">
    {children}
  </h2>
)

export const DialogFooter: React.FC<{
  children: ReactNode
  className?: string
}> = ({ children, className = "" }) => (
  <div className={`mt-auto px-6 pb-6 flex justify-end space-x-2 ${className}`}>{children}</div>
)

export const DialogClose: React.FC<{
  onClose: () => void
  className?: string
}> = ({ onClose, className = "" }) => (
  <button
    onClick={onClose}
    className={`text-neutral-400 hover:text-neutral-600 ${className}`}
    aria-label="Close dialog"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  </button>
)

//.......................................................................................... Select

export const Select = SelectPrimitive.Root

export const SelectGroup = SelectPrimitive.Group

export const SelectValue = SelectPrimitive.Value

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    label?: string
    error?: string
    id?: string
  }
>(({ className, children, label, error, id, ...props }, ref) => (
  <div className="flex flex-col">
    {label && (
      <label htmlFor={id} className="mb-1 text-sm font-medium text-neutral-700">
        {label}
      </label>
    )}
    <SelectPrimitive.Trigger
      ref={ref}
      id={id}
      className={`
        flex h-10 w-full items-center justify-between 
        bg-white
        rounded-md border 
        px-3 py-2 
        text-sm
        focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent
        disabled:cursor-not-allowed disabled:opacity-50
        ${error ? "border-red-500" : "border-neutral-300"}
        ${className}`}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="h-4 w-4 text-neutral-500" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
    {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
  </div>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={`
        relative z-50 min-w-[8rem] overflow-hidden
        rounded-md border border-neutral-200
        bg-white 
        text-neutral-900
        shadow-lg
        animate-in fade-in-0
        ${position === "popper" ? "translate-y-1" : ""}
        ${className}`}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={`p-1 ${
          position === "popper"
            ? "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
            : ""
        }`}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={`py-1.5 pl-8 pr-2 text-sm font-medium text-neutral-700 ${className}`}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={`
      relative flex w-full cursor-default select-none items-center
      rounded-sm py-1.5 pl-8 pr-2 
      text-sm 
      outline-none
      hover:bg-neutral-100
      focus:bg-primary-50 
      ${
        props.disabled
          ? `pointer-events-none
             text-neutral-300`
          : "text-neutral-900 focus:text-primary-900"
      }
      ${className}`}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="h-4 w-4 text-primary-500" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={`-mx-1 my-1 h-px bg-neutral-200 ${className}`}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

//................................................................................... Tabs

export const Tabs = TabsPrimitive.Root

export const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className = "", ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={`inline-flex h-10 items-center justify-center rounded-lg bg-neutral-100 p-1 text-neutral-500 ${className}`}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

export const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className = "", ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={`
      inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium 
      ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 
      focus-visible:ring-neutral-400 focus-visible:ring-offset-2 disabled:pointer-events-none 
      disabled:opacity-50 
      data-[state=active]:bg-white data-[state=active]:text-neutral-900 data-[state=active]:shadow-sm
      ${className}`}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

export const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className = "", ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={`mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 
    focus-visible:ring-neutral-400 focus-visible:ring-offset-2 ${className}`}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

//................................................................................... MultiSelect

export interface MultiSelectOption {
  label: string
  value: string
}

interface MultiSelectProps extends Omit<ReactSelectProps<MultiSelectOption, true>, "onChange"> {
  onChange: (value: MultiSelectOption[]) => void
  label?: string
}

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: "35px",
    border: state.isFocused ? "1px solid #3b82f6" : "1px solid #d1d5db",
    boxShadow: state.isFocused ? "0 0 0 1px #3b82f6" : "none",
    "&:hover": {
      border: "1px solid #9ca3af",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "35px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: "0.875rem",
    backgroundColor: state.isSelected ? "#3b82f6" : state.isFocused ? "#bfdbfe" : "white",
    color: state.isSelected ? "white" : "#1f2937",
    padding: "4px 8px",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#e5e7eb",
    borderRadius: "4px",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    fontSize: "0.75rem",
    padding: "1px 4px",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#6b7280",
    padding: "1px",
    "&:hover": {
      backgroundColor: "#d1d5db",
      color: "#1f2937",
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    border: "1px solid #d1d5db",
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: "2px",
  }),
}

const MultiValueRemove = (props: any) => (
  <components.MultiValueRemove {...props}>
    <X size={12} />
  </components.MultiValueRemove>
)

// Add this new component to hide the clear indicator
const ClearIndicator = () => {
  return null
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, onChange, ...props }) => {
  const selectRef = useRef<any>(null)

  const handleChange = (selectedOptions: readonly MultiSelectOption[] | null) => {
    onChange(selectedOptions as MultiSelectOption[])
  }

  return (
    <div>
      {label && <label className="block mb-1 text-sm font-medium text-neutral-700">{label}</label>}
      <ReactSelect
        {...props}
        ref={selectRef}
        styles={customStyles}
        components={{ MultiValueRemove, ClearIndicator }}
        onChange={handleChange}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        className="react-select-container"
        classNamePrefix="react-select"
        isClearable={false}
      />
    </div>
  )
}

// ....................................................................................... Spinner

interface LoadingSpinnerProps {
  size?: "default" | "xs" | "sm" | "lg"
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = "default" }) => {
  const widthMap = {
    default: 80,
    xs: 40,
    sm: 60,
    lg: 80,
  }

  return (
    <div className={`flex justify-center items-center`}>
      <BarLoader width={widthMap[size]} color={"currentColor"} />
    </div>
  )
}

export const LoadingOverlay: React.FC<
  LoadingSpinnerProps & {
    text?: string
    secondaryText?: string
    center?: boolean
  }
> = ({ size, text, secondaryText, center }) => {
  return (
    <div
      className={`absolute inset-0 z-50 flex flex-col justify-center items-center text-neutral-800`}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      }}
    >
      <div style={{ flexGrow: 1 }} />
      {(text || secondaryText) && (
        <div className="pb-4 text-center space-y-2">
          {text && <p className="text-neutral-800">{text}</p>}
          {secondaryText && <p className="text-neutral-500 text-sm">{secondaryText}</p>}
        </div>
      )}
      <LoadingSpinner size={size} />
      <div style={{ flexGrow: center || !text ? 1 : 1.5 }} />
    </div>
  )
}

// ..................................................................................... Table

export const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(
  ({ className, ...props }, ref) => (
    <table ref={ref} className={`w-full caption-bottom text-sm ${className || ""}`} {...props} />
  )
)
Table.displayName = "Table"

export const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={`sticky top-0 bg-background z-10 ${className || ""}`} {...props} />
))
TableHeader.displayName = "TableHeader"

export const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={`[&_tr:last-child]:border-0 ${className || ""}`} {...props} />
))
TableBody.displayName = "TableBody"

export const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={`border-t bg-muted/50 font-medium [&>tr]:last:border-b-0 ${className || ""}`}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

export const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr ref={ref} className={`border-b transition-colors ${className || ""}`} {...props} />
))
TableRow.displayName = "TableRow"

export const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={`h-10 px-2 text-left align-middle font-medium  [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] ${
      className || ""
    }`}
    {...props}
  />
))
TableHead.displayName = "TableHead"

export const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={`p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px] ${
      className || ""
    }`}
    {...props}
  />
))
TableCell.displayName = "TableCell"

export const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={`mt-4 text-sm ${className || ""}`} {...props} />
))
TableCaption.displayName = "TableCaption"

// .......................................................................................... Pill

type PillVariant =
  | "neutral"
  | "blue"
  | "red"
  | "green"
  | "yellow"
  | "purple"
  | "success"
  | "error"
  | "warning"
  | "info"

type PillSize = "sm" | "md" | "lg"

interface PillProps {
  children: React.ReactNode
  variant?: PillVariant
  size?: PillSize
  className?: string
}

export const Pill = ({ children, variant = "neutral", size = "md", className = "" }: PillProps) => {
  const baseStyles = "inline-flex items-center rounded-full font-medium"

  const variantStyles: Record<PillVariant, string> = {
    neutral: "bg-neutral-100 text-neutral-800",
    blue: "bg-blue-100 text-blue-800",
    red: "bg-red-100 text-red-800",
    green: "bg-green-100 text-green-800",
    yellow: "bg-yellow-100 text-yellow-800",
    purple: "bg-purple-100 text-purple-800",
    success: "bg-green-100 text-green-800",
    error: "bg-red-100 text-red-800",
    warning: "bg-yellow-100 text-yellow-800",
    info: "bg-blue-100 text-blue-800",
  }

  const sizeStyles: Record<PillSize, string> = {
    sm: "px-2 py-0.5 text-xs",
    md: "px-2 py-1 text-xs",
    lg: "px-3 py-1.5 text-sm",
  }

  return (
    <span
      className={`
        ${baseStyles}
        ${variantStyles[variant]}
        ${sizeStyles[size]}
        ${className}
      `}
    >
      {children}
    </span>
  )
}

// .......................................................................................... Card

type CardBaseProps = { className?: string; hoverable?: boolean }

type CardComponent = <C extends React.ElementType = "div">(
  props: PolymorphicComponentProp<C, CardBaseProps> & { ref?: PolymorphicRef<C> }
) => React.ReactElement | null

// @ts-expect-error
export const Card: CardComponent = React.forwardRef(
  // @ts-expect-error
  <C extends React.ElementType = "div">(
    { as, className = "", hoverable, ...props }: PolymorphicComponentProp<C, CardBaseProps>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "div"
    return (
      <Component
        ref={ref}
        className={`rounded-lg bg-white border border-neutral-200 flex flex-col items-stretch  ${
          hoverable ? "hover:shadow-lg transition-all hover:border-neutral-300" : ""
        } ${className}`}
        {...props}
      />
    )
  }
)

// @ts-expect-error
Card.displayName = "Card"

export const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className = "", ...props }, ref) => (
    <div ref={ref} className={`flex flex-col space-y-4 p-6 ${className}`} {...props} />
  )
)
CardHeader.displayName = "CardHeader"

const titleVariants = cva("font-semibold space-y-4", {
  variants: {
    size: {
      default: "text-lg",
      sm: "text-base",
      lg: "text-xl",
      xl: "text-2xl",
    },
    color: {
      default: "text-neutral-900",
      primary: "text-primary-500",
      neutral: "text-neutral-900",
      red: "text-red-500",
      green: "text-green-700",
      blue: "text-blue-700",
    },
  },
  defaultVariants: {
    size: "default",
    color: "default",
  },
})

interface CardTitleProps
  extends Omit<React.HTMLAttributes<HTMLHeadingElement>, "color">,
    VariantProps<typeof titleVariants> {
  icon?: LucideIcon
}

export const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  ({ className = "", size, color, icon, ...props }, ref) => {
    const TitleIcon = icon

    return (
      <div className={titleVariants({ size, color, className })}>
        {TitleIcon && <TitleIcon className="inline-block h-6 w-6" />}
        <h3 ref={ref} {...props} />
      </div>
    )
  }
)
CardTitle.displayName = "CardTitle"

const subtitleVariants = cva("text-neutral-500 leading-normal", {
  variants: {
    size: {
      default: "text-sm",
      sm: "text-xs",
      lg: "text-base",
      xl: "text-lg",
    },
  },
  defaultVariants: {
    size: "default",
  },
})

interface CardSubtitleProps
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof subtitleVariants> {}

export const CardSubtitle = React.forwardRef<HTMLParagraphElement, CardSubtitleProps>(
  ({ className = "", size, ...props }, ref) => (
    <p ref={ref} className={subtitleVariants({ size, className })} {...props} />
  )
)
CardSubtitle.displayName = "CardSubtitle"

export const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className = "", ...props }, ref) => (
  <p ref={ref} className={`text-sm ${className}`} {...props} />
))
CardDescription.displayName = "CardDescription"

export const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className = "", ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 flex-1 ${className}`} {...props} />
  )
)
CardContent.displayName = "CardContent"

export const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className = "", ...props }, ref) => (
    <div ref={ref} className={`flex items-center p-6 pt-0 ${className}`} {...props} />
  )
)
CardFooter.displayName = "CardFooter"

const cardImageVariants = cva("w-full object-cover", {
  variants: {
    position: {
      top: "rounded-t-lg",
      bottom: "rounded-b-lg",
      middle: "",
    },
    aspectRatio: {
      auto: "",
      square: "aspect-square",
      video: "aspect-video",
      portrait: "aspect-[3/4]",
    },
    fit: {
      cover: "object-cover",
      contain: "object-contain",
    },
  },
  defaultVariants: {
    position: "top",
    aspectRatio: "video",
    fit: "cover",
  },
})

interface CardImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "className">,
    VariantProps<typeof cardImageVariants> {
  className?: string
  fallback?: React.ReactNode
  outerClassName?: string
}

export const CardImage = React.forwardRef<HTMLImageElement, CardImageProps>(
  (
    {
      position,
      aspectRatio,
      fit,
      alt = "",
      src,
      fallback,
      outerClassName = "",
      className = "",
      ...props
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [hasError, setHasError] = React.useState(false)

    const handleLoad = () => setIsLoading(false)
    const handleError = () => {
      setIsLoading(false)
      setHasError(true)
    }

    if (hasError && fallback) {
      return <>{fallback}</>
    }

    return (
      <div
        className={`relative ${cardImageVariants({
          position,
          aspectRatio,
          className,
        })} ${outerClassName}`}
      >
        {isLoading && <div className="absolute inset-0 bg-neutral-100 animate-pulse" />}
        <img
          ref={ref}
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          className={cardImageVariants({ position, aspectRatio, fit, className })}
          {...props}
        />
      </div>
    )
  }
)

CardImage.displayName = "CardImage"

// .......................................................................................... Card

export interface ProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  value: number
  max?: number
  color?: "primary" | "secondary" | "success" | "warning" | "error"
  size?: "sm" | "md" | "lg"
  showValue?: boolean
  label?: string
  valueLabel?: string
}

export const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(
  (
    {
      value,
      max = 100,
      color = "primary",
      size = "md",
      showValue = false,
      label,
      valueLabel,
      className = "",
      ...props
    },
    ref
  ) => {
    const percentage = (value / max) * 100

    const colors = {
      primary: "bg-primary-500",
      secondary: "bg-neutral-500",
      success: "bg-green-500",
      warning: "bg-yellow-500",
      error: "bg-red-500",
    }

    const sizes = {
      sm: "h-1",
      md: "h-2",
      lg: "h-3",
    }

    return (
      <div className="space-y-2">
        {(label || valueLabel) && (
          <div className="flex justify-between text-sm">
            {label && <span>{label}</span>}
            {valueLabel && <span>{valueLabel}</span>}
          </div>
        )}
        <div ref={ref} className={`w-full bg-neutral-200 rounded-full ${className}`} {...props}>
          <div
            className={`${colors[color]} ${sizes[size]} rounded-full transition-all duration-300 ease-in-out`}
            style={{ width: `${Math.min(100, Math.max(0, percentage))}%` }}
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={max}
          />
        </div>
        {showValue && (
          <div className="text-sm text-neutral-600 text-right">{Math.round(percentage)}%</div>
        )}
      </div>
    )
  }
)
Progress.displayName = "Progress"

//............................................................................................ Alert

const alertVariants = cva(
  "relative rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive: "border-red-500/50 text-red-500 dark:border-red-500 [&>svg]:text-red-500",
        success: "border-green-500/50 text-green-500 dark:border-green-500 [&>svg]:text-green-500",
        warning:
          "border-orange-500/50 text-orange-500 dark:border-orange-500 [&>svg]:text-orange-500",
        info: "border-blue-500/50 text-blue-500 dark:border-blue-500 [&>svg]:text-blue-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const icons = {
  default: Info,
  destructive: XCircle,
  success: CheckCircle,
  warning: AlertTriangle,
  info: Info,
}

interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof alertVariants> {
  onClose?: () => void
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className = "", variant = "default", children, onClose, ...props }, ref) => {
    const Icon = icons[variant || "default"]

    return (
      <div ref={ref} role="alert" className={alertVariants({ variant, className })} {...props}>
        <Icon className="h-4 w-4" />
        {children}
        {onClose && (
          <button
            onClick={onClose}
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>
        )}
      </div>
    )
  }
)
Alert.displayName = "Alert"

export const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className = "", ...props }, ref) => (
  <h5
    ref={ref}
    className={`mb-1 font-medium leading-none tracking-tight ${className}`}
    {...props}
  />
))
AlertTitle.displayName = "AlertTitle"

export const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className = "", ...props }, ref) => (
  <div ref={ref} className={`text-sm [&_p]:leading-relaxed ${className}`} {...props} />
))
AlertDescription.displayName = "AlertDescription"

//..................................................................................... Alert Dialog

interface AlertDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  children: React.ReactNode
}

export const AlertDialog: React.FC<AlertDialogProps> = ({ children, open, onOpenChange }) => {
  const [isMouseDownOnBackdrop, setIsMouseDownOnBackdrop] = React.useState(false)

  React.useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onOpenChange(false)
      }
    }

    if (open) {
      document.addEventListener("keydown", handleEscape)
      try {
        document.body.style.overflow = "hidden"
      } catch {
        // ignore
      }
    }

    return () => {
      document.removeEventListener("keydown", handleEscape)
      try {
        document.body.style.overflow = ""
      } catch {
        // ignore
      }
    }
  }, [open, onOpenChange])

  const handleBackdropMouseDown = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsMouseDownOnBackdrop(true)
    }
  }

  const handleBackdropMouseUp = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget && isMouseDownOnBackdrop) {
      onOpenChange(false)
    }
    setIsMouseDownOnBackdrop(false)
  }

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
          onMouseDown={handleBackdropMouseDown}
          onMouseUp={handleBackdropMouseUp}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
}

export const AlertDialogContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      transition={{ type: "spring", damping: 20, stiffness: 300 }}
      className="bg-white rounded-lg shadow-xl w-full max-w-md"
      role="alertdialog"
      aria-modal="true"
    >
      {children}
    </motion.div>
  )
}

export const AlertDialogHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="px-6 pt-6">{children}</div>
}

export const AlertDialogTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <h2 className="text-xl font-semibold" id="alert-dialog-title">
      {children}
    </h2>
  )
}

export const AlertDialogDescription: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="mt-2 text-neutral-600">{children}</div>
}

export const AlertDialogFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="px-6 py-4 flex justify-end space-x-2">{children}</div>
}

export const AlertDialogCancel: React.FC<{
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}> = ({ children, onClick, disabled }) => {
  return (
    <Button disabled={disabled} onClick={onClick} color="neutral" variant="subtle">
      {children}
    </Button>
  )
}

//............................................................................................ Separator

export const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(({ className, orientation = "horizontal", decorative = true, ...props }, ref) => (
  <SeparatorPrimitive.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={`shrink-0 bg-neutral-200 
        ${orientation === "horizontal" ? "h-px w-full" : "h-full w-px"} 
        ${className}`}
    {...props}
  />
))

Separator.displayName = SeparatorPrimitive.Root.displayName
